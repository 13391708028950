import { store } from "@/store/store";
import { medicamento } from "@/shared/dtos/Historial_clinico/medicamento";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "medicamentoModule",
  store,
  dynamic: true,
})
class medicamentoModule extends VuexModule {
  public medicamentos: medicamento[] = [];
  public medicamento: medicamento = new medicamento();

  @Action({ commit: "onGetmedicamentos" })
  public async getmedicamentos(nombre: string) {
    return await ssmHttpService.get(
      API.medicamento + "/medicamentos_like?like_nombre=" + nombre
    );
  }

  @Action({ commit: "onGetmedicamentos" })
  public async get_all_medicamentos() {
    return await ssmHttpService.get(API.medicamento, null, false);
  }

  @Action({ commit: "onGetmedicamentos" })
  public async gethist_clinicos_medicamentos_acivas(id_paciente: number) {
    return await ssmHttpService.get(
      API.hist_clinico + "/medicamentos_acivas/" + id_paciente
    );
  }

  @Action({ commit: "onGetmedicamento" })
  public async getmedicamento(id: any) {
    return await ssmHttpService.get(API.medicamento + "/" + id);
  }

  @Action
  public async guardarmedicamento(medicamento: medicamento) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(API.medicamento, medicamento.toJson());
  }

  @Action
  public async modificarmedicamento(medicamento: medicamento) {
    await ssmHttpService.put(API.medicamento, medicamento);
  }

  @Action
  public async eliminarmedicamento(medicamento: medicamento) {
    await ssmHttpService.delete(
      API.medicamento + "/" + medicamento.id,
      null,
      false
    );
  }

  @Mutation
  public onGetmedicamentos(res: medicamento[]) {
    this.medicamentos = res ? res.map((x) => new medicamento(x)) : [];
  }

  @Mutation
  public onGetmedicamento(res: medicamento) {
    this.medicamento = new medicamento(res);
  }
}
export default getModule(medicamentoModule);
